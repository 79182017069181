.summary_header h1 {
  float: left;
  width: 50%;
  margin-top: 17px;
  white-space: nowrap;
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.summary_header:after {
  clear: both;
  content: "";
  display: block;
}

#pagelisting_sortoptions {
  text-align: right;
  list-style-type: none;
  margin: 0;
  padding: 15px 0 30px;
  white-space: nowrap;
}
#pagelisting_sortoptions > li {
  text-align: left;
  padding: 0;
  border: 1px solid #919191;
  border-radius: 15px;
  color: #919191;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-family: "Barlow", Sans-Serif, Arial;
  font-weight: 300;
  height: 30px;
  margin-left: 10px;
  min-width: 135px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;
  background-color: #fff;
}
#pagelisting_sortoptions > li:before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: inherit;
  position: absolute;
  background-color: #fff;
  top: 10px;
  left: 10px;
}
#pagelisting_sortoptions > li:hover, #pagelisting_sortoptions > li.active {
  background-color: #3BA1FA;
  color: #fff;
  border-color: #3BA1FA;
}
#pagelisting_sortoptions > li a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 30px;
  padding: 6px 15px 4px 30px;
}
@media (max-width: 1000px) {
  #pagelisting_sortoptions > li {
    min-width: 95px;
    border-radius: 0;
    margin-left: -1px;
  }
  #pagelisting_sortoptions > li:before {
    display: none;
  }
  #pagelisting_sortoptions > li a {
    padding-left: 15px;
  }
  #pagelisting_sortoptions > li:first-child {
    border-radius: 15px 0 0 15px;
  }
  #pagelisting_sortoptions > li:last-child {
    border-radius: 0 15px 15px 0;
  }
}

@media (max-width: 750px) {
  .summary_header h1 {
    float: none;
    width: 100%;
    text-align: center;
  }
  #pagelisting_sortoptions {
    text-align: center;
    padding-top: 0;
  }
  .mediaheader + main #pagelisting_sortoptions {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -143px;
  }
}
ul.summary {
  list-style-type: none;
  margin: 0 0 0 -20px;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
ul.summary:after {
  content: "";
  display: block;
  clear: both;
}
ul.summary li {
  width: 33.3333%;
  float: left;
  display: flex;
  padding: 0 0 20px 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(1, 1);
  opacity: 1;
}
ul.summary li.hide {
  display: none;
}
ul.summary li.beforesort {
  transform: scale(0, 0);
  opacity: 0;
}
ul.summary li > a {
  display: block;
  position: relative;
  text-decoration: none;
  width: 100%;
  color: inherit;
  font-family: "Barlow", Sans-Serif, Arial;
  font-size: 20px;
  line-height: 22px;
  transform: translate3d(0, 0, 0);
}
ul.summary li > a .image {
  display: block;
  position: relative;
  background-color: #3BA1FA;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25%;
  overflow: hidden;
}
ul.summary li > a .image:before {
  content: "";
  display: block;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1; /* on top */
  transition: opacity 0.3s;
}
ul.summary li > a .image.image--placeholder {
  background-image: url(../../web/img/hare-white.png);
}
ul.summary li > a .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, opacity 1s;
}
ul.summary li > a .image:after {
  content: "";
  padding-top: 54.5454545455%;
  display: block;
}
ul.summary li > a:hover .image:before {
  z-index: 1;
  opacity: 0.7;
}
ul.summary li > a:hover .image > img {
  transform: scale(1.1, 1.1);
}
ul.summary li > a:hover .image--placeholder {
  background-size: 40%;
  background-image: url(../../web/img/hare_ani.gif);
}
ul.summary li > a .title {
  padding-top: 10px;
  font-weight: 500;
  display: block;
}
ul.summary li > a .description {
  padding-top: 2px;
  font-weight: 200;
  display: block;
  font-size: 16px;
}
ul.summary li > a .date {
  font-size: 14px;
  color: #999;
}
@media (max-width: 650px) {
  ul.summary > li {
    width: 50%;
  }
}
@media (max-width: 600px) {
  ul.summary > li {
    float: none;
    width: 100%;
    min-height: 20vw;
    margin-bottom: 15px;
  }
  ul.summary > li a {
    padding-left: calc(20vw + 15px);
  }
  ul.summary > li a .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
    height: 20vw;
  }
  ul.summary > li a .image.image--placeholder {
    background-size: 50%;
  }
  ul.summary > li a .image > img {
    height: 100%;
    width: auto;
    left: -9999px;
    right: -9999px;
    margin: 0 auto;
  }
  ul.summary > li a:hover .image--placeholder {
    background-size: 60%;
  }
}