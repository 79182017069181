@charset "UTF-8";
/* Global page, HTML5 setup, style reset */
html, body, p, h1, h2, h3, h4, h5, h6, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *::before, *::after {
  -moz-box-sizing: border-box; /* Firefox version 28- (including 24 ESR) */
  -webkit-box-sizing: border-box; /* Android Browser version 3- */
  box-sizing: border-box;
}

html {
  background-color: #fff;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
  min-height: 100%;
}

html.manual,
html.reference {
  background-color: #e9e9e9;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}
@media screen {
  body {
    overflow-x: hidden;
    display: flex;
    width: 100vw;
    min-height: 100vh;
    flex-direction: column;
  }
}

.fab.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 100%;
  font-weight: 700;
  font-family: Arial-black;
}

html.manual > body,
html.reference > body {
  position: relative;
}

.centercontainer {
  list-style-type: none;
  display: block;
  width: 100%;
  padding: 0 30px;
  max-width: 1084px; /* 1024 + 2x 30px */
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .centercontainer {
    padding: 0 20px;
    max-width: 1064px; /* 1024 + 2x 20px */
  }
}
@media (max-width: 600px) {
  .centercontainer {
    padding: 0 15px;
  }
}
.grid8width {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1084px; /* 1024 + 2x 30px */
  margin: 0 auto;
}
@media (min-width: 1001px) {
  html.site--intranet .grid8width {
    padding-left: 130px;
  }
}
@media (min-width: 1001px) and (max-width: 1290px) {
  html.site--intranet .grid8width {
    max-width: calc(100% - 205px);
  }
}
@media (min-width: 900px) and (max-width: 1000px) {
  html.site--intranet .grid8width {
    padding-left: 0;
  }
}
@media (max-width: 1000px) {
  .grid8width {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 600px) {
  .grid8width {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mediaheader {
  position: relative;
  font-family: "Barlow", Sans-Serif, Arial;
}
.mediaheader .previouspage, .mediaheader .nextpage {
  font-weight: 200;
  color: #fff;
  position: absolute;
  top: 50%;
  text-decoration: none;
  background-color: rgba(74, 74, 74, 0.85);
  height: 72px;
  margin-top: -36px;
  display: flex;
  padding: 10px 15px;
  font-size: 14px;
  align-items: middle;
  min-width: 150px;
  max-width: 210px;
  align-items: center;
  transition: background-color 0.15s;
}
.mediaheader .previouspage .fa, .mediaheader .nextpage .fa {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 24px;
  padding: 24px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 30px;
}
.mediaheader .previouspage:hover, .mediaheader .nextpage:hover {
  background-color: rgb(74, 74, 74);
}
.mediaheader .previouspage {
  padding-left: 45px;
  border-radius: 0 7px 7px 0;
  left: 0;
}
.mediaheader .previouspage .fa {
  left: 0;
}
.mediaheader .nextpage {
  padding-right: 45px;
  border-radius: 7px 0 0 7px;
  right: 0;
}
.mediaheader .nextpage > .title {
  display: block;
  width: 100%;
  text-align: right;
}
.mediaheader .nextpage .fa {
  right: 0;
}
@media (max-width: 1000px) {
  .mediaheader .previouspage, .mediaheader .nextpage {
    display: none;
  }
}
.mediaheader .wh-carrousel__items__item {
  background-position: 0 0;
  background-repeat: no-repeat;
  min-height: 300px;
}
.mediaheader .wh-carrousel__items__item__link {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-decoration: none;
}
.mediaheader .wh-carrousel__items__item.active .wh-carrousel__items__item__link {
  display: block;
}
.mediaheader .wh-carrousel__items__item:before {
  content: "";
  padding-top: 24.3%; /* 1440 x 350 */
  display: block;
}
@media (max-width: 600px) {
  .mediaheader .wh-carrousel__items__item {
    min-height: 175px;
  }
}
.mediaheader .wh-carrousel--large .wh-carrousel__items__item:before {
  padding-top: 37.5%; /* 1440 x 540 */
}
.mediaheader .wh-carrousel--small {
  background-position: 50% 50%;
  background-size: cover;
}
.mediaheader .wh-carrousel--large .textoverlay {
  padding-bottom: 20px; /* extra space for jump buttons */
}
.mediaheader .wh-carrousel--large.wh-carrousel--single .textoverlay {
  padding-bottom: 0;
}
.mediaheader .textoverlay {
  white-space: normal;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  transform: translate3d(0, 0, 0);
}
.mediaheader .textoverlay:before {
  /* gradient */
  content: "";
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75%;
  position: absolute;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.mediaheader .textoverlay .centercontainer {
  position: relative;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .mediaheader .textoverlay .centercontainer {
    padding-bottom: 2vw;
  }
}
.mediaheader .textoverlay .centercontainer.text--bottom h1 {
  align-self: flex-end;
}
.mediaheader .textoverlay h1 {
  font-size: 48px;
  line-height: 54px;
  padding-left: 100px;
  font-family: inherit;
}
html.client .mediaheader .textoverlay h1 {
  font-weight: 200;
}
.mediaheader .textoverlay h1 small {
  display: block;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
}
.mediaheader .textoverlay h1 span + small {
  font-size: inherit;
  line-height: inherit;
}
@media (max-width: 1000px) {
  .mediaheader .textoverlay h1 {
    padding-left: 0px;
  }
}
@media (max-width: 1000px) {
  .mediaheader .textoverlay h1 {
    font-size: 4.8vw;
    line-height: 5.4vw;
  }
  .mediaheader .textoverlay h1 span + small {
    font-size: inherit;
    line-height: inherit;
  }
}
@media (max-width: 500px) {
  .mediaheader .textoverlay h1 {
    font-size: 24px;
    line-height: 26px;
  }
  .mediaheader .textoverlay h1 small {
    font-size: 18px;
    line-height: 18px;
  }
  html.topic .mediaheader .textoverlay h1 {
    font-size: 4.8vw;
    line-height: 5.4vw;
  }
}
html.topic .mediaheader .textoverlay h1 {
  font-weight: 300;
}
html.topic .mediaheader .textoverlay h1 span {
  display: inline-block;
  height: 82px;
  padding: 17px 45px 0 95px;
  border-radius: 41px;
  border: 2px solid #fff;
  position: relative;
}
html.topic .mediaheader .textoverlay h1 span:before {
  content: "";
  position: absolute;
  display: block;
  top: 28px;
  left: 24px;
  border: 2px solid #fff;
  border-radius: 100%;
  width: 23px;
  height: 23px;
}
html.topic .mediaheader .textoverlay h1 small {
  padding-left: 95px;
  margin-top: 5px;
  font-size: 30px;
}
@media (max-width: 1000px) {
  html.topic .mediaheader .textoverlay h1 span {
    height: 10.222vw;
    padding: 2.3vw 5vw 0 10vw;
  }
  html.topic .mediaheader .textoverlay h1 span:before {
    top: 3.5vw;
    left: 3vw;
    width: 2.55vw;
    height: 2.55vw;
  }
  html.topic .mediaheader .textoverlay h1 small {
    padding-left: 10vw;
    font-size: 4vw;
  }
}

.withactivesubnav + .mediaheader .textoverlay {
  top: 74px;
}

@media (max-width: 1000px) {
  .withactivesubnav + .mediaheader .textoverlay {
    top: 60px;
  }
}
@media (max-width: 600px) {
  .withactivesubnav + .mediaheader .textoverlay {
    top: 24px;
  }
}
.mediaheader__map {
  position: relative;
  height: 350px;
  z-index: 0;
}

.mediaheader__mapoverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}
.mediaheader__mapoverlay .textoverlay:before {
  display: none;
}
.mediaheader__mapoverlay .directions {
  pointer-events: all;
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 50px;
  color: inherit;
  text-decoration: none;
  padding: 17px 120px 13px 20px;
  line-height: 20px;
  background-color: #fff;
  opacity: 0.9;
  z-index: 1000;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.31);
  border-radius: 1px;
  transition: opacity 0.15s;
}
.mediaheader__mapoverlay .directions:hover {
  opacity: 1;
}
.mediaheader__mapoverlay .directions .fa {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 17px;
}

@media (max-width: 1000px) {
  .mediaheader__map {
    height: 38vw;
  }
  .mediaheader__mapoverlay .directions {
    left: 20px;
    top: 25px;
  }
}
@media (max-width: 600px) {
  .mediaheader__map {
    height: 190px;
  }
  .mediaheader__mapoverlay .directions {
    left: 15px;
    top: 15px;
    padding: 10px 65px 10px 15px;
  }
  .mediaheader__mapoverlay .directions .fa {
    top: 10px;
    right: 15px;
  }
}
#pathnav {
  width: 100%;
  max-width: 884px;
  margin: 15px auto;
  display: block;
  padding: 0 30px;
  position: relative;
  left: 0;
}
#pathnav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#pathnav ol li {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #848484;
}
#pathnav ol li + li:before {
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Pro";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  padding: 0 8px;
}
#pathnav ol a {
  color: inherit;
  text-decoration: none;
}
#pathnav ol a:hover {
  text-decoration: underline;
}
@media (max-width: 1084px) {
  html #pathnav {
    margin-left: 12.5%;
    margin-right: 12.5%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1000px) {
  html #pathnav {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 600px) {
  html #pathnav {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1460px) {
  html.manual #pathnav, html.reference #pathnav {
    margin-left: 283px;
    margin-right: auto;
    padding-left: 30px;
  }
}
@media (max-width: 1137px) {
  html.manual #pathnav, html.reference #pathnav {
    margin-left: auto;
  }
}

header.withactivesubnav + #pathnav {
  padding-top: 74px; /* 74px extra space for visible subnav in header*/
}

@media (max-width: 1000px) {
  header.withactivesubnav + #pathnav {
    padding-top: 60px; /* remove extra space for visible subnav in header*/
  }
}
@media (max-width: 600px) {
  header.withactivesubnav + #pathnav {
    padding-top: 43px; /* remove extra space for visible subnav in header*/
  }
}
.sitemessage {
  display: none;
  background-color: #3BA1FA;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.97;
  height: 0;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.sitemessage .centercontainer {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.sitemessage.scrollable .centercontainer {
  padding-top: 50px; /* extra space for topbar */
}
.sitemessage .content {
  padding-left: 100px;
  padding-right: 200px;
}
@media (max-width: 1000px) {
  .sitemessage .content {
    padding-left: 0px;
  }
}
.sitemessage .buttonholder {
  position: absolute;
  top: 40px;
  right: 20px;
}
.sitemessage .close {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 30px;
  border: 1px solid #fff;
  background-color: transparent;
  color: inherit;
  border-radius: 15px;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 15px;
}
.sitemessage .close:before {
  display: inline-block;
  position: absolute;
  right: 14px;
  top: 13px;
  content: "";
  width: 13px;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}
.sitemessage .close:after {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 7px;
  content: "";
  height: 13px;
  border-left: 1px solid #fff;
  transform: rotate(45deg);
}
@media (max-width: 600px) {
  .sitemessage .content {
    padding-right: 0;
  }
  .sitemessage .buttonholder {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-left: 15px;
    text-align: right;
  }
}
.sitemessage p:last-child {
  margin-bottom: 0;
}
.sitemessage a {
  color: inherit;
}