.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 15px 0 !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.wh-autocomplete-values > li {
  padding: 3px 15px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.15s;
  outline: none;
  color: #4a4a4a;
  margin-bottom: 0 !important;
}
.wh-autocomplete-values > li:before {
  display: none !important;
}
.wh-autocomplete-values > li.noresults {
  font-weight: normal;
  font-style: italic;
  color: #999;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  background-color: #f8f8f8;
}
.wh-autocomplete-values > li .match {
  font-weight: normal;
}

.contentlist {
  list-style-type: none;
  margin: 10px 0 50px;
  padding: 0;
}
.contentlist li {
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
}
.contentlist li + li {
  border-top: 0 none;
}
.contentlist a {
  display: block;
  position: relative;
  text-decoration: none;
  padding-left: 170px;
  min-height: 100px;
  color: #4A4A4A;
}
.contentlist a .image {
  width: 155px;
  height: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #3BA1FA url("../../web/img/hare-white.png") 50% 50% no-repeat;
}
.contentlist a .image > img {
  transition: transform 0.15s;
  position: absolute;
  top: 0;
  right: -9999px;
  bottom: 0;
  left: -9999px;
  margin: 0 auto;
}
.contentlist a:hover .image > img {
  transform: scale(1.05, 1.05);
}
.contentlist a .title {
  display: block;
}
.contentlist a .filesize {
  color: #999;
  font-weight: normal;
  font-size: 14px;
}
@media (max-width: 600px) {
  .contentlist a {
    padding-left: 110px;
  }
  .contentlist a .image {
    height: 80px;
    width: 80px;
    background-size: 80%;
  }
}

/* more btn used in sitesearch */
#moreresults {
  color: #fff;
  display: none;
  margin: 18px 0 60px;
  padding: 14px;
  text-align: center;
  height: 60px;
  cursor: pointer;
  border-radius: 2px;
  font: 300 24px/30px "Barlow", Sans-Serif, Arial;
  position: relative;
  background-color: #3BA1FA;
}

html.loadingresults #moreresults .more,
#moreresults .loading {
  display: none;
}

#moreresults .more {
  position: relative;
}

#moreresults .more > .fa {
  font-size: 43px;
  position: absolute;
  left: 50%;
  margin-left: -22px;
  top: 45px;
  transition: top 0.2s;
  color: #3BA1FA;
}

#moreresults:hover .more > .fa {
  top: 49px;
}

html.loadingresults #moreresults .loading {
  display: block;
}

#moreresults.active {
  display: block;
}

html.loadingresults #moreresults .more,
#moreresults .loading {
  display: none;
}

#moreresults .more {
  position: relative;
}

#moreresults .more > .fa {
  font-size: 43px;
  position: absolute;
  left: 50%;
  margin-left: -22px;
  top: 45px;
  transition: top 0.2s;
}

#moreresults:hover .more > .fa {
  top: 49px;
}

html.loadingresults #moreresults .loading {
  display: block;
}

#moreresults.active {
  display: block;
}

#resultscount {
  margin-bottom: 30px;
}

#resultscount .multi,
#resultscount.single .single {
  display: inline-block;
}

#resultscount .single,
#resultscount.single .multi,
#loadingresults,
html.loadingresults #resultscount {
  display: none;
}

html.loadingresults #loadingresults {
  display: block;
}

@media (max-width: 600px) {
  html.programme #resultscount,
  html.programme #loadingresults {
    padding: 20px;
  }
  #selectedsearchfilters {
    padding-top: 0;
  }
}
#headersearchform {
  position: relative;
  top: -120px;
  padding-top: 0;
  font-family: "Barlow", Sans-Serif, Arial;
}
@media (max-width: 600px) {
  #headersearchform {
    top: -110px;
  }
}
#headersearchform input, #headersearchform button {
  height: 39px;
  border: 0 none;
  border-radius: 0;
  text-align: left;
  padding: 5px 15px;
  line-height: 29px;
  font-size: 20px;
  outline: none;
  -webkit-appearance: none;
}
#headersearchform input {
  background-color: #fff;
  width: 100%;
  padding-right: 150px;
}
#headersearchform button {
  position: absolute;
  background-color: #3BA1FA;
  right: 0;
  top: 0;
  width: 140px;
  color: #fff;
  font-family: inherit;
  cursor: pointer;
  transition: opacity 0.15s;
  font-weight: 300;
  padding-top: 8px;
  padding-left: 20px;
}
#headersearchform button:hover {
  opacity: 0.9;
}
#headersearchform button .fa {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 10px;
}
@media (max-width: 1000px) {
  #headersearchform input {
    width: 100%;
  }
}
@media (max-width: 400px) {
  #headersearchform input {
    padding-right: 50px;
  }
  #headersearchform button {
    width: 40px;
  }
  #headersearchform button > .label {
    display: none;
  }
}

#searchresults {
  margin-top: -20px;
}
#searchresults .sectionhilight {
  padding-bottom: 15px;
}
#searchresults .sectionhilight h2 > .count {
  font-weight: 300;
  font-family: "Barlow", Sans-Serif, Arial;
}
#searchresults .sectionhilight #resultscount {
  padding-top: 0;
}
#searchresults .sectionhilight .more {
  color: #3BA1FA;
  display: inline-block;
  text-decoration: none;
  position: relative;
  top: -35px;
}
#searchresults .sectionhilight .more > .fa {
  display: inline-block;
  padding-right: 8px;
}
#searchresults .sectionhilight .more:hover {
  text-decoration: underline;
}