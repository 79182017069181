.wh-carrousel {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  background-size: auto 100%;
  background-position: -50% 0;
  background-repeat: repeat-x;
  position: relative;
}

.wh-carrousel:focus {
  outline: none;
}

.wh-carrousel__previous,
.wh-carrousel__next {
  display: inline-block;
  position: absolute;
  width: 46px;
  top: 16px;
  bottom: 45px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 33px;
  display: block;
  cursor: pointer;
}
.wh-carrousel__previous:hover,
.wh-carrousel__next:hover {
  background-color: rgba(0, 0, 0, 0.95);
}
.wh-carrousel__previous > .fa,
.wh-carrousel__next > .fa {
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

.widget_photoalbum--withtitles .wh-carrousel__previous,
.widget_photoalbum--withtitles .wh-carrousel__next {
  top: 45px;
}

.wh-carrousel__previous {
  left: -50px;
  transition: background-color 0.2s, left 0.2s;
}
.wh-carrousel__previous.active {
  left: 0;
}
.wh-carrousel__previous > .fa {
  left: 15px;
}

.wh-carrousel__next {
  right: -50px;
  transition: background-color 0.2s, right 0.2s;
}
.wh-carrousel__next.active {
  right: 0;
}
.wh-carrousel__next > .fa {
  left: 18px;
}

.wh-carrousel--single:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wh-carrousel__items {
  display: block;
  padding: 0;
  margin: 0 auto;
  max-width: 1440px; /* max limit of slide size */
  width: 100%;
  position: relative;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0); /* force hardware acceleration for safari */
  transform: translate3d(0, 0, 0);
}

.wh-carrousel__items__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.wh-carrousel__items__item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.25;
  transition: opacity 400ms;
  pointer-events: none;
}
.wh-carrousel__items__item.active:after {
  opacity: 0;
}
.wh-carrousel__items__item:before {
  content: "";
  display: block;
  padding-top: 40%;
}

.wh-carrousel__jumpbuttons {
  display: block;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  min-height: 15px;
}
.wh-carrousel__jumpbuttons > span {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  margin: 0 3px 3px;
  opacity: 0.5;
  transition: opacity 0.15s transform 0.15s;
}
.wh-carrousel__jumpbuttons > span.active, .wh-carrousel__jumpbuttons > span:hover {
  opacity: 1;
}
.wh-carrousel__jumpbuttons > span.active {
  cursor: default;
  transform: scale(1.2, 1.2);
}

@media (max-width: 1000px) {
  .wh-carrousel__jumpbuttons {
    bottom: 0;
  }
}
@media (max-width: 600px) {
  .wh-carrousel__jumpbuttons {
    display: none;
  }
}