footer {
  background-color: #3B3B3B;
  color: #fff;
  position: relative;
  font-family: "Barlow", Sans-Serif, Arial;
  min-height: 60px;
}
html.manual footer, html.reference footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
}
footer > .centercontainer {
  position: relative;
}
footer a {
  color: inherit;
  text-decoration: none;
  font-family: "Barlow", Sans-Serif, Arial;
}
footer #topicsnav {
  padding-top: 25px;
  margin-left: -15px;
  margin-bottom: 75px;
}
footer #topicsnav .title {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 30px;
}
footer #topicsnav a {
  display: inline-block;
  font-size: 14px;
  height: 30px;
  line-height: 20px;
  padding: 5px 15px 5px 25px;
  border-radius: 15px;
  border: 1px solid #999;
  margin-left: 15px;
  margin-bottom: 15px;
  position: relative;
  transition: all 0.2s;
}
footer #topicsnav a:before {
  content: "";
  display: block;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  border: inherit;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -4px;
}
footer #topicsnav a:hover, footer #topicsnav a.active {
  border-color: #3BA1FA;
  background-color: #3BA1FA;
  text-decoration: none;
}
footer #topicsnav a:hover:before, footer #topicsnav a.active:before {
  border-color: #fff;
  background-color: #fff;
}
footer .bottomnav {
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #5a5a5a;
  font-size: 15px;
  line-height: 22px;
  white-space: nowrap;
  color: #E9E9E9;
}
footer .footernote {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  white-space: normal;
}
footer .footernote > .centercontainer {
  position: relative;
  margin: 0 auto;
  padding: 14px 0 0;
  max-width: 824px;
  font-size: 13px;
  line-height: 16px;
}
footer .footernote > .centercontainer:before {
  content: "*";
  font-size: 16px;
  display: inline-block;
  position: absolute;
  left: -8px;
  top: 14px;
}
@media (max-width: 1100px) {
  footer .footernote > .centercontainer {
    margin: 0 0 0 140px;
  }
}
@media (max-width: 1000px) {
  footer .footernote > .centercontainer {
    padding: 10px 0 0;
  }
}
@media (max-width: 600px) {
  footer .footernote > .centercontainer {
    margin-left: 90px;
  }
}
footer #metanav {
  padding-top: 19px;
  font-weight: 200;
}
footer #metanav > div {
  display: inline-block;
}
footer #metanav > div + div {
  margin-left: 5vw;
}
footer #metanav a {
  margin-right: 25px;
  display: inline-block;
  position: relative;
  float: left;
}
footer #metanav a.withicon {
  padding-left: 23px;
}
footer #metanav a.withicon[href^=tel] {
  padding-left: 20px;
}
footer #metanav a.withicon .icon {
  text-align: right;
  position: absolute;
  top: 4px;
  left: 0;
}
footer #metanav a.withicon .mobile {
  display: none;
}
footer #metanav a:hover {
  text-decoration: underline;
  color: #fff;
}
footer #socialnav {
  position: absolute;
  top: 50%;
  height: 22px;
  right: calc(50% - 512px);
  margin-top: -11px;
}
footer #socialnav > a {
  font-size: 21px;
  display: inline-block;
  margin-left: 25px;
}
footer #socialnav > a:hover {
  text-decoration: none;
  color: #51C3F6;
}
@media (max-width: 1084px) {
  footer #socialnav {
    right: 30px;
  }
}
@media (max-width: 1000px) {
  footer {
    min-height: 50px;
    /*
          & #metanav .hidemobile
        , & #socialnav
        {
          display: none;
        }
    */
  }
  html.manual footer, html.reference footer {
    height: 50px;
  }
  footer #topicsnav {
    padding-top: 15px;
    margin-bottom: 55px;
    margin-left: -10px;
  }
  footer #topicsnav > .title {
    padding-bottom: 15px;
  }
  footer #topicsnav a {
    font-size: 12px;
    height: 24px;
    padding: 1px 15px 2px 25px;
    border-radius: 12px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  footer #socialnav {
    right: 20px;
  }
  footer #socialnav > a {
    margin-left: 20px;
  }
  footer .bottomnav {
    height: 50px;
  }
  footer #metanav {
    padding-top: 15px;
    /*
          & .contactnav a
          {
            margin-right: 0;
            margin-left: 20px;
            padding-left: 20px;

            & .fa
            {
              top: 4px;
            }

            &[href^='tel']
            {
              padding-left: 15px;
            }
          }
          */
  }
  footer #metanav a {
    margin-right: 15px;
  }
}
@media (max-width: 850px) {
  footer #socialnav {
    display: none;
  }
  footer #metanav {
    display: flex;
    justify-content: space-between;
  }
  footer #metanav .contactnav a {
    margin-right: 0;
    margin-left: 20px;
    padding-left: 20px;
  }
  footer #metanav .contactnav a .fa {
    top: 4px;
  }
  footer #metanav .contactnav a[href^=tel] {
    padding-left: 15px;
  }
}
@media (max-width: 600px) {
  footer #metanav .hidemobile {
    display: none;
  }
  footer #metanav {
    white-space: nowrap;
    font-size: 13px;
  }
  footer #metanav a {
    margin-right: 0;
    float: none;
  }
  footer #metanav .contactnav a {
    float: none;
  }
  footer #metanav .contactnav a .desktop {
    display: none;
  }
  footer #metanav .contactnav a .fa-envelope-o {
    top: 2px;
  }
  footer #metanav .contactnav a .mobile {
    display: inline-block;
  }
  footer #metanav > div + div {
    margin-left: 0;
  }
}