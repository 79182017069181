html.sidenav--active body {
  overflow-y: hidden;
}

.downloadpdf {
  padding: 0 20px 0 40px;
  white-space: nowrap;
}
.downloadpdf .icon {
  font-size: 20px;
  padding-right: 10px;
}

/* basic sidenav used for intranet */
@media (min-width: 1455px) {
  html.site--intranet.basicsidenavleft main > .wh-form {
    margin-left: calc(50% - 410px);
  }
}
@media (max-width: 1455px) and (min-width: 1001px) {
  html.site--intranet.basicsidenavleft main > * {
    margin-left: 190px;
    width: calc(100% - 75px);
  }
  html.site--intranet.basicsidenavleft main > .wh-form {
    margin-left: 320px;
    width: calc(100% - 350px);
  }
}
@media (max-width: 1000px) and (min-width: 900px) {
  html.site--intranet.basicsidenavleft main > * {
    margin-left: 295px;
    width: calc(100% - 295px);
  }
  html.site--intranet.basicsidenavleft main > .wh-form {
    margin-top: 10px;
    margin-left: 320px;
    width: calc(100% - 350px);
  }
}

/* basic sidenav used for intranet */
#basicsidenav {
  position: absolute;
  left: calc(50% - 705px);
  top: 40px;
  max-width: 245px;
  margin: 0;
  padding: 15px 15px 15px 20px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.25);
}
@media (max-width: 1455px) {
  #basicsidenav {
    left: 25px;
  }
  html:not(.site--intranet) #basicsidenav {
    position: relative;
    float: left;
    left: 0;
    top: 0;
    margin: 0 30px 30px 25px;
  }
}
@media (max-width: 900px) {
  #basicsidenav {
    display: none;
  }
}
#basicsidenav a {
  display: block;
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}
#basicsidenav .active > a,
#basicsidenav a:hover {
  color: #2486DB;
}
#basicsidenav > ul > li > a {
  font-family: "Barlow", Sans-Serif, Arial;
  font-weight: 500;
  text-transform: uppercase;
}
#basicsidenav > ul > li > ul {
  font-size: 15px;
}
#basicsidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#basicsidenav ul > li {
  padding: 4px 0;
  display: block;
}
#basicsidenav ul li > ul {
  padding-left: 15px;
}

/* sidenav for manuals: */
#sidenav {
  position: absolute;
  left: 25px;
  top: 30px;
  max-width: 160px;
  margin: 0;
  padding: 0 15px 15px 0;
}
html.manual #sidenav, html.reference #sidenav {
  min-height: 100%;
  width: 260px;
  max-width: 260px;
  left: -290px;
  top: 0;
  padding-right: 0;
  padding-bottom: 100px;
}
#sidenav .scrollcontainer {
  width: calc(100% - 10px);
  position: relative;
}
#sidenav .closebtn {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
#sidenav .closebtn:before, #sidenav .closebtn:after {
  position: absolute;
  display: block;
  content: "";
  transform: rotate(45deg);
}
#sidenav .closebtn:before {
  border-top: 1px solid #4a4a4a;
  width: 15px;
  top: 14px;
  left: 7px;
}
#sidenav .closebtn:after {
  border-left: 1px solid #4a4a4a;
  height: 15px;
  top: 7px;
  left: 14px;
}
#sidenav .title {
  font-family: "Barlow", Sans-Serif, Arial;
  color: #3BA1FA;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  padding-bottom: 3px;
}
html.manual #sidenav .title, html.reference #sidenav .title {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 10px;
}
#sidenav .more {
  padding: 7px 24px 7px 36px;
  line-height: 20px;
  white-space: nowrap;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #979797;
  display: inline-block;
  position: relative;
  background-color: #fff;
}
#sidenav .more > .icon {
  width: 10px;
  height: 10px;
  background-color: #3BA1FA;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 15px;
}
#sidenav .more > .icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
}
#sidenav .more > .icon:after {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #fff;
}
#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 15px;
}
#sidenav a {
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  line-height: 17px;
  transition: color 0.15s;
}
#sidenav a:hover {
  color: #3BA1FA;
}
#sidenav li {
  display: block;
  padding-left: 20px;
  margin-bottom: 4px;
  position: relative;
}
#sidenav li.active > a {
  color: #3BA1FA;
}
#sidenav li:before {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 1px;
  width: 7px;
  height: 7px;
  border: 1px solid #3BA1FA;
  border-radius: 100%;
}
#sidenav ul.clients > li:before {
  border-radius: 0;
  border: 0 none;
  top: 3px;
  font: normal normal normal 16px/1 "Font Awesome 5 Pro";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  color: #3BA1FA;
}
#sidenav ul.tree {
  counter-reset: li;
  padding-left: 20px;
}
#sidenav ul.tree > li:before {
  border-radius: 0;
  border: 0 none;
  display: inline-block;
  content: counter(li) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: -20px;
  font-weight: 500;
}
#sidenav ul.tree > li.active:before, #sidenav ul.tree > li:hover:before {
  color: #3BA1FA;
}
#sidenav ul.tree ul > li:before {
  display: none;
}
#sidenav ul.tree ul {
  padding: 0;
  margin-top: 10px;
}
#sidenav ul.tree > li, #sidenav ul.tree li {
  margin: 0;
  padding: 0;
}
#sidenav ul.tree li + li {
  margin-top: 10px;
}
#sidenav ul.tree a {
  display: block;
  font-size: 15px;
}
#sidenav ul.tree > li > a {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 1200px) {
  #sidenav {
    position: relative;
    top: 0;
    left: 0;
    float: left;
    margin-left: calc(50% - 412px); /* 50% - (1084 / 2) - 130 */
  }
  html.manual #sidenav, html.reference #sidenav {
    position: absolute;
    margin-left: 0;
    left: -290px;
  }
}
@media (max-width: 1048px) {
  #sidenav {
    margin-left: 12.5%;
  }
}
@media (max-width: 1000px) {
  #sidenav {
    margin-left: 20px;
  }
}
@media (max-width: 600px) {
  #sidenav {
    display: none;
  }
}
html.manual #sidenav, html.reference #sidenav {
  position: fixed;
  top: 148px; /* topbars space */
  left: calc(50% - 714px);
  bottom: 0;
  width: 300px;
  min-width: 300px;
  padding: 50px 0 150px;
  z-index: 1;
  min-height: 0;
  z-index: 0; /* below footer */
}
html.manual #sidenav #sidenavsearchform, html.reference #sidenav #sidenavsearchform {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
html.manual #sidenav.scroll:before, html.manual #sidenav.scroll:after, html.reference #sidenav.scroll:before, html.reference #sidenav.scroll:after {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: #ccc;
}
html.manual #sidenav:before, html.reference #sidenav:before {
  top: 51px;
}
html.manual #sidenav:after, html.reference #sidenav:after {
  bottom: 150px;
}
html.manual #sidenav .scrollcontainer, html.reference #sidenav .scrollcontainer {
  position: relative;
  padding: 0 20px;
  max-height: 100%;
  overflow-y: auto;
  /* IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html.manual #sidenav.scroll .scrollcontainer:hover, html.reference #sidenav.scroll .scrollcontainer:hover {
  -webkit-overflow-scrolling: auto;
}
html.manual #sidenav.scroll .scrollcontainer:hover::-webkit-scrollbar, html.reference #sidenav.scroll .scrollcontainer:hover::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
html.manual #sidenav.scroll .scrollcontainer:hover::-webkit-scrollbar-thumb, html.reference #sidenav.scroll .scrollcontainer:hover::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
html.manual #sidenav.scroll .scrollcontainer, html.reference #sidenav.scroll .scrollcontainer {
  padding: 15px 20px;
}
@media (max-width: 1460px) {
  html.manual #sidenav, html.reference #sidenav {
    left: 16px;
  }
}
@media (max-width: 1137px) {
  html.manual #sidenav, html.reference #sidenav {
    display: none;
    left: -325px;
    background-color: #fff;
  }
  html.manual #sidenav .closebtn, html.reference #sidenav .closebtn {
    display: block;
  }
}
@media (max-width: 1000px) {
  html.manual #sidenav, html.reference #sidenav {
    top: 64px;
  }
}
@media (max-width: 600px) {
  html.manual #sidenav, html.reference #sidenav {
    top: 48px;
  }
}

#sidenavsearchform {
  margin: 15px 0;
  background-color: #fff;
  height: 30px;
  border-radius: 15px;
  position: relative;
}
#sidenavsearchform input, #sidenavsearchform button {
  -webkit-appearance: none;
  height: 30px;
  background-color: transparent;
  border-radius: 0;
  border: 0 none;
  outline: none;
}
#sidenavsearchform input {
  font-size: 15px;
  line-height: 20px;
  padding: 5px 40px 5px 20px;
  width: 100%;
}
#sidenavsearchform button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  cursor: pointer;
}
@media (max-width: 1137px) {
  html.manual #sidenavsearchform, html.reference #sidenavsearchform {
    height: 32px;
    border: 1px solid #999;
  }
  html.manual #sidenavsearchform button, html.reference #sidenavsearchform button {
    color: #4A4A4A;
  }
}

.sidenavtoggle {
  background-color: #2778BE;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  position: fixed;
  top: 75%;
  right: -60px;
  opacity: 0;
  font-size: 24px;
  text-align: center;
  padding-top: 13px;
  transition: right 0.3s, opacity 0.2s;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.sidenavtoggle.visible {
  opacity: 1;
  right: 20px;
}